<template>
  <div class="params-config">
    <a-tabs v-model:activeKey="activeKey" size="large">
      <a-tab-pane key="1" tab="网站信息">
        <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-item label="公告标题">
            <a-input v-model:value.trim="noticeTitle" placeholder="请输入公告标题" allowClear></a-input>
          </a-form-item>
          <a-form-item label="公告内容">
            <a-textarea v-model:value.trim="noticeContent" placeholder="请输入公告内容" allowClear></a-textarea>
          </a-form-item>
          <a-form-item label="整站维护">
            <a-select v-model:value="websiteMaintain">
              <a-select-option :value="1">关闭</a-select-option>
              <a-select-option :value="0">开启</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="维护提示">
            <a-textarea v-model:value.trim="maintainMessage" placeholder="请输入维护提示" allowClear></a-textarea>
          </a-form-item>
          <a-form-item label="Android版下载二维码">
            <a-upload-dragger
              class="cover-file"
              :file-list="androidFileList"
              :remove="removeFile"
              :customRequest="androidSelfUpload"
              @change="handleAndroidChange"
            >
              <p class="ant-upload-drag-icon" v-show="!androidDownloadQrCode">
                <plus-outlined></plus-outlined>
              </p>
              <img :src="androidDownloadQrCode" v-show="androidDownloadQrCode" class="cover-img" />
            </a-upload-dragger>
          </a-form-item>
          <a-form-item label="IOS版下载二维码">
            <a-upload-dragger
              class="cover-file"
              :file-list="iphoneFileList"
              :remove="removeFile"
              :customRequest="iphoneSelfUpload"
              @change="handleIphoneChange"
            >
              <p class="ant-upload-drag-icon" v-show="!iphoneDownloadQrCode">
                <plus-outlined></plus-outlined>
              </p>
              <img :src="iphoneDownloadQrCode" v-show="iphoneDownloadQrCode" class="cover-img" />
            </a-upload-dragger>
          </a-form-item>
          <a-form-item :wrapperCol="{ span: 12, offset: 12 }">
            <a-button type="primary" @click="saveWebsiteConfig">保存</a-button>
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="App信息" class="app-info">
        <app-config></app-config>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, onMounted } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import api from '@/services';
import AppConfig from './AppConfig';
import { message } from 'ant-design-vue';
export default defineComponent({
  components: {
    PlusOutlined,
    AppConfig
  },
  setup() {
    const activeKey = ref('1');
    const labelCol = reactive({
      span: 4,
      offset: 2
    });
    const wrapperCol = reactive({
      span: 14
    });

    const formState = reactive({
      id: '',
      noticeTitle: '',
      noticeContent: '',
      websiteMaintain: 1,
      maintainMessage: '',
      androidDownloadQrCode: '',
      iphoneDownloadQrCode: ''
    });

    const androidFileList = ref([]);
    const iphoneFileList = ref([]);

    onMounted(() => {
      getWebsiteConfig();
    });

    // 获取网站设置信息
    const getWebsiteConfig = async () => {
      const { success, data } = await api.getWebsiteConfig();
      if (success) {
        Object.keys(formState).map(item => {
          formState[item] = data[item];
        });
        formState.androidDownloadQrCode = 'data:image/jpg;base64,' + data.androidDownloadQrCode;
        formState.iphoneDownloadQrCode = 'data:image/jpg;base64,' + data.iphoneDownloadQrCode;
      }
    };

    // 编辑网站设置信息
    const saveWebsiteConfig = async () => {
      let params = {
        ...formState
      };
      const { success } = await api.saveWebsiteConfig(params);
      if (success) {
        message.success('保存成功！');
      }
    };

    const androidSelfUpload = ({ file }) => {
      selfUpload(file, 0);
    };
    const iphoneSelfUpload = ({ file }) => {
      selfUpload(file, 1);
    };

    // base64转换
    const selfUpload = (file, type) => {
      new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          formState[!type ? 'androidDownloadQrCode' : 'iphoneDownloadQrCode'] = fileReader.result;
        };
      });
    };

    const handleAndroidChange = info => {
      handleChange(info, 0);
    };
    const handleIphoneChange = info => {
      handleChange(info, 1);
    };
    // 修改上传状态 和 限制上传数量
    const handleChange = (info, type) => {
      let resFileList = [...info.fileList];
      resFileList = resFileList.slice(-1);
      console.log(typeof type);
      if (!type) {
        androidFileList.value = resFileList;
        if (androidFileList.value.length) androidFileList.value[0].status = 'done';
      } else {
        iphoneFileList.value = resFileList;
        if (iphoneFileList.value.length) iphoneFileList.value[0].status = 'done';
      }
    };

    // 删除
    const removeFile = () => {
      formState.iphoneDownloadQrCode = '';
    };

    return {
      ...toRefs(formState),
      activeKey,
      androidFileList,
      iphoneFileList,
      labelCol,
      wrapperCol,
      removeFile,
      handleChange,
      saveWebsiteConfig,
      getWebsiteConfig,
      handleAndroidChange,
      handleIphoneChange,
      androidSelfUpload,
      iphoneSelfUpload,
      selfUpload
    };
  }
});
</script>

<style lang="less" scoped>
.params-config {
  padding: 15px 20px;
  :deep(.ant-form) {
    .ant-form-item {
      .ant-upload {
        width: 150px;
        height: 150px;
      }
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}
</style>
